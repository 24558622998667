import styled from 'styled-components';

const TextArea = styled.textarea`
  width: 100%;
  margin-top: 1rem;
  font-size: 1.5rem;
  padding: 1rem;
  text-align: center;
  border: 1px solid #333;
`;

export default TextArea;
