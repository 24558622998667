import styled from 'styled-components';

const Input = styled.input`
  width: 100%;
  margin-top: 1rem;
  font-size: 1.25rem;
  padding: 1rem;
  text-align: left;
  border: 1px solid #333;
`;

export default Input;
