/* eslint-disable */
import React, { useReducer, useState } from 'react';
import styled from 'styled-components';
import { StyledButton } from '../shared/components/Button';

import Field from '../shared/components/Form/Field';
import Form from '../shared/components/Form/Form';
import Input from '../shared/components/Form/Input';
import TextArea from '../shared/components/Form/TextArea';
import Label from '../shared/components/Form/Label';
import Layout from '../shared/components/Layouts/Inner';
import SEO from '../shared/components/SEO';

import encode from '../shared/helpers/encode';
import formReducer from '../shared/reducers/formReducer';
import ContainerFixed from '../shared/components/Layouts/ContainerFixed';

import ContactDetails from '../shared/components/ContactDetails';

import Iframe from 'react-iframe';

const ThridsGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 30px;
  margin: 2rem;

  @media (max-width: 750px) {
    display: block;

    div {
      &:first-child {
        margin-bottom: 2rem;
      }
    }
  }

  iframe {
    border: none;
  }
`;

const initialState = {
  name: '',
  website: '',
  email: '',
  phone: '',
};

const Contact = () => {
  const [state, dispatch] = useReducer(formReducer, initialState);

  const handleChange = (event) => {
    dispatch({ field: event.target.name, value: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = {
      ...state,
    };

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...formData }),
    })
      .then(() => alert('Success!'))
      .catch((error) => alert(error));
  };
  return (
    <Layout url="contact" darkMode>
      <SEO title="Contact" />
      <ContainerFixed>
        <h1
          style={{
            textTransform: 'uppercase',
            textAlign: 'center',
            letterSpacing: 1,
            marginTop: '3rem',
          }}
        >
          Contact Us
        </h1>
        <ThridsGrid>
          <div>
            <Form
              method="post"
              action=""
              name="contact"
              onSubmit={handleSubmit}
              netlify
              netlify-honeypot="bot-field"
              data-netlify="true"
            >
              <Input type="hidden" name="bot-field" />
              <Field>
                <Label htmlFor="name">
                  Name · <em>Required</em>
                  <Input
                    type="text"
                    name="name"
                    onChange={(event) => handleChange(event)}
                    required
                  />
                </Label>
              </Field>
              <Field>
                <Label htmlFor="email">
                  Email Address · <em>Required</em>
                  <Input
                    type="email"
                    name="email"
                    onChange={(event) => handleChange(event)}
                    required
                  />
                </Label>
              </Field>
              <Field>
                <Label htmlFor="phone">
                  Phone Number
                  <Input type="tel" name="phone" onChange={(event) => handleChange(event)} />
                </Label>
              </Field>
              <Field>
                <Label htmlFor="message">
                  Message
                  <TextArea
                    type="tel"
                    rows="3"
                    name="message"
                    onChange={(event) => handleChange(event)}
                  />
                </Label>
              </Field>
              <StyledButton type="submit" style={{ width: '15rem', alignSelf: 'center' }}>
                Submit
              </StyledButton>
            </Form>
          </div>
          <div>
            <h2>Our Details</h2>
            <p>
              Sunco House 5 Carliol Square <br />
              Newcastle upon Tyne
              <br />
              United Kingdom
              <br />
              NE1 6UF
            </p>
            <ContactDetails />
            <Iframe
              title="Office Location"
              url="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d349140.5678739957!2d-1.8613414406099185!3d54.90853696708636!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487e70c82178b3ef%3A0xfb7162f117a4760!2sMaid%20Up%20North!5e0!3m2!1sen!2suk!4v1590001469546!5m2!1sen!2suk"
              width="100%"
              height="400px"
              display="initial"
              position="relative"
            />
          </div>
        </ThridsGrid>
      </ContainerFixed>
    </Layout>
  );
};

export default Contact;
