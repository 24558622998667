import styled from 'styled-components';

const Field = styled.fieldset`
  display: flex;
  flex-direction: column;
  border: 0;
  margin-bottom: 2rem;
`;

export default Field;
